import fileUpload from '../../assets/homepage/promo/file-upload.png';
import alexa from '../../assets/homepage/promo/amazon-alexa.svg';

export const promoSectionLarge = [
  {
    id: 1,
    iconData: {
      src: fileUpload,
      width: 63,
      height: 86,
    },
    title: 'Choose Your Investor Type, Asset Classes, and Topics & Securities',
    mainButton: {
      text: 'Personalise',
      url: '/news/onboarding/watchlist',
    },
    secondaryButton: null,
    big: true,
    type: 'files',
  },
  {
    id: 2,
    iconData: {
      src: alexa,
      width: 72,
      height: 84,
    },
    title: 'Manage Financial Content And Your Portfolios By Voice',
    mainButton: {
      text: 'Learn More',
      url: '/products/voiceassistant',
    },
    secondaryButton: null,
    type: 'voice',
    isReload: true,
  },
];

export default promoSectionLarge;
