import etoro from '../assets/homepage/trusted_by/etoro.svg';
import paribas from '../assets/homepage/trusted_by/bnp-paribas-1.svg';
import obrain from '../assets/homepage/trusted_by/brain.svg';
import iex from '../assets/homepage/trusted_by/iex.svg';
import plum from '../assets/homepage/trusted_by/plum.svg';
import seedrs from '../assets/homepage/trusted_by/seedrs.svg';
import intellibonds from '../assets/homepage/trusted_by/intelli-bonds.svg';
import stonex from '../assets/homepage/trusted_by/stonex.svg';
import figg from '../assets/homepage/trusted_by/figg.svg';
import p3 from '../assets/homepage/trusted_by/p3.svg';
import stocktwits from '../assets/homepage/trusted_by/stocktwits.svg';

export default [
  {
    logo: etoro,
    width: 78,
    height: 26,
    mobileSizes: {
      width: 64,
      height: 21,
    },
    tabSizes: {
      width: 64,
      height: 15,
    },
    bigSizes: {
      width: 168,
      height: 52,
    },
    alt: 'etoro',
  },
  {
    logo: paribas,
    width: 123,
    height: 27,
    mobileSizes: {
      width: 101,
      height: 21,
    },
    tabSizes: {
      width: 101,
      height: 21,
    },
    bigSizes: {
      width: 246,
      height: 54,
    },
    alt: 'bnp',
  },
  {
    logo: stocktwits,
    width: 112,
    height: 23,
    mobileSizes: {
      width: 80,
      height: 16,
    },
    tabSizes: {
      width: 80,
      height: 16,
    },
    bigSizes: {
      width: 224,
      height: 46,
    },
    alt: 'stocktwits',
  },
  {
    logo: iex,
    width: 45,
    height: 28,
    mobileSizes: {
      width: 37,
      height: 23,
    },
    tabSizes: {
      width: 37,
      height: 23,
    },
    bigSizes: {
      width: 101,
      height: 64,
    },
    alt: 'iex',
  },
  {
    logo: plum,
    width: 75.75,
    height: 24,
    mobileSizes: {
      width: 75.75,
      height: 24,
    },
    tabSizes: {
      width: 75.75,
      height: 24,
    },
    bigSizes: {
      width: 151.5,
      height: 48,
    },
    alt: 'plum',
  },
  {
    logo: stonex,
    width: 76,
    height: 25,
    mobileSizes: {
      width: 63,
      height: 20,
    },
    tabSizes: {
      width: 63,
      height: 18,
    },
    bigSizes: {
      width: 152,
      height: 50,
    },
    alt: 'stonex',
  },
  {
    logo: seedrs,
    width: 80,
    height: 21,
    mobileSizes: {
      width: 65,
      height: 18,
    },
    tabSizes: {
      width: 65,
      height: 18,
    },
    bigSizes: {
      width: 160,
      height: 41,
    },
    alt: 'seedrs',
  },
  {
    logo: figg,
    width: 75,
    height: 29,
    mobileSizes: {
      width: 46,
      height: 18,
    },
    tabSizes: {
      width: 46,
      height: 18,
    },
    bigSizes: {
      width: 133,
      height: 52,
    },
    alt: 'figg',
  },
  {
    logo: obrain,
    width: 91,
    height: 16,
    mobileSizes: {
      width: 74,
      height: 13,
    },
    tabSizes: {
      width: 74,
      height: 14,
    },
    bigSizes: {
      width: 181,
      height: 31,
    },
    alt: 'obrain',
  },
  {
    logo: intellibonds,
    width: 119,
    height: 14,
    mobileSizes: {
      width: 97,
      height: 12,
    },
    tabSizes: {
      width: 97,
      height: 12,
    },
    bigSizes: {
      width: 237,
      height: 28,
    },
    alt: 'intellibonds',
  },
  {
    logo: p3,
    width: 53,
    height: 29,
    mobileSizes: {
      width: 33,
      height: 18,
    },
    tabSizes: {
      width: 33,
      height: 18,
    },
    bigSizes: {
      width: 96,
      height: 53,
    },
    alt: 'p3',
  },
].map((item, index) => ({
  ...item,
  id: index,
}));
